// TODO
// After replacing Neutral Button with Pills from ui-corporate,
// this component can be deleted

import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import Loading from './ButtonLoader';

import useUrlAttributes from '@/utils/useUrlAttributes';

function getButtonSizeStyles($size) {
  switch ($size) {
    case 'sm':
      return css`
        font-size: 14px;
        padding: 0 11px;
        height: 50px;
      `;
    case 'lg':
      return css`
        height: 48px;
        padding: 12px 32px;
        font-size: 16px;
      `;
    default:
      return css`
        font-size: 14px;
        height: 40px;
        padding: 0 16px;
      `;
  }
}

const ButtonStyle = styled.button`
  border-radius: 100px;
  font-family: ${(props) => props.theme.typography.family.title};
  font-weight: 500;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;

  ${(props) => getButtonSizeStyles(props.$size)}

  ${(props) =>
    props.loading &&
    css`
      color: transparent !important;
      pointer-events: none;
    `}
  
  ${(props) =>
    props.$custom &&
    css`
      align-items: center;
      display: inline-flex;
      justify-content: center;
    `}
`;

const Button = React.forwardRef(
  (
    {
      big, // deprecated: use size
      small, // deprecated: use size
      size = 'md',
      loading,
      children,
      onClick,
      className,
      href,
      loadingColor,
      type,
      ...rest
    },
    ref,
  ) => {
    const urlAttributes = useUrlAttributes(href || '');
    const isCustomComponent = useMemo(() => !!rest?.as, [rest]);
    const buttonSize = useMemo(() => (big ? 'lg' : small ? 'sm' : size), [big, small, size]);

    return (
      <ButtonStyle
        ref={ref}
        className={className}
        loading={loading}
        onClick={onClick}
        $size={buttonSize}
        $custom={isCustomComponent}
        href={href}
        {...(href ? { ...urlAttributes } : {})}
        {...rest}
        {...(isCustomComponent ? {} : { type: type || 'button' })}
      >
        <Loading loading={loading} loadingColor={loadingColor} />
        {children}
      </ButtonStyle>
    );
  },
);

Button.displayName = 'Button';

export default Button;
