'use client';

import { useState } from 'react';
import { Form as FormikForm, Formik } from 'formik';
import styled from 'styled-components';
import { Button } from '@/components/core/Button';
import ScrollToFieldError from './ScrollToFieldError';
import { Paragraph } from '../Paragraph';
import Alert from './Alert';
import { Heading } from '@swordhealth/ui-corporate';
import Section from '../Section';
import { scrollToSection } from '@/utils/scrollToSection';

import errorIcon from '@swordhealth/ui-corporate/icons/colored/co-generic-error-yellow.svg?url';
import successIcon from '@swordhealth/ui-corporate/icons/colored/co-check-alt-yellow.svg?url';

export const StyledForm = styled(FormikForm)`
  display: grid;

  [type='submit'] {
    margin-top: 40px;
    justify-self: center;
  }
`;

const StyledHeader = styled(Section)`
  display: grid;
  gap: ${(props) => props.theme.spacings.sm};

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    gap: ${(props) => props.theme.spacings.md};
  }
`;

const Form = ({
  className,
  canSubmit = true,
  children,
  formTitle,
  formTitleProps = { as: 'h4', size: 'md' },
  formDescription,
  formError,
  formSuccess,
  formId = 'form',
  onSubmit,
  submitLabel = 'Send',
  initialError = false,
  scrollToTop = true,
  ...props
}) => {
  const [{ error, success }, setStatus] = useState({
    initialError,
    error: false,
    success: false,
  });

  return (
    <Formik
      {...props}
      onSubmit={async (values) => {
        try {
          await onSubmit(values);

          setStatus({ error: false, success: true });
        } catch (error) {
          setStatus({ error: true, success: false });
        } finally {
          if (scrollToTop) {
            scrollToSection({ sectionId: formId, isReduced: true });
          }
        }
      }}
    >
      {({ isSubmitting }) => (
        <StyledForm id={formId} className={className} noValidate>
          {success && (
            <Alert
              image={{ src: successIcon, width: 80, height: 80 }}
              {...formSuccess}
              onClose={() => {
                setStatus({ error: false, success: false });

                if (formSuccess.onClose) {
                  formSuccess.onClose();
                }
              }}
            />
          )}
          {(initialError || error) && (
            <Alert
              {...formError}
              image={{ src: errorIcon, width: 80, height: 80 }}
              primaryButton={{ label: 'Try again' }}
              onClose={() =>
                initialError
                  ? window.location.reload()
                  : setStatus({ error: false, success: false })
              }
            />
          )}
          {!initialError && !error && !success && (
            <>
              {formTitle && (
                <StyledHeader forwardedAs="header" innerSpacer>
                  {formTitle && <Heading {...formTitleProps}>{formTitle}</Heading>}
                  {formDescription && <Paragraph>{formDescription}</Paragraph>}
                </StyledHeader>
              )}
              <div>{children}</div>
              <Button type="submit" loading={isSubmitting} disabled={!canSubmit}>
                {submitLabel}
              </Button>
              <ScrollToFieldError />
            </>
          )}
        </StyledForm>
      )}
    </Formik>
  );
};

export default Form;
