import MarkdownRichText from '@/components/shared/MarkdownRichText';
import { Paragraph as UIParagraph, RichText } from '@swordhealth/ui-corporate';

function Paragraph({
  as = 'div',
  children,
  className,
  forwardedAs,
  isMarkdown = true,
  markdownProps = {},
  size = 'md',
  highlightLinks = true,
  ...rest
}) {
  return (
    <UIParagraph as={as} forwardedAs={forwardedAs} size={size} className={className}>
      <RichText
        {...rest}
        as={markdownProps?.elements?.includes('p') ? 'div' : 'span'}
        highlightLinks={highlightLinks}
      >
        {isMarkdown ? <MarkdownRichText {...markdownProps}>{children}</MarkdownRichText> : children}
      </RichText>
    </UIParagraph>
  );
}

export default Paragraph;
