'use client';

import { useCallback } from 'react';
import { usePathname } from 'next/navigation';
import { ACTIONS, BUTTON_TYPES, trackButtonClicked } from './track';
import { isEnrollmentHostname } from './useUrlWithParams';

type UseButtonTrackingProps = {
  label: string;
  location?: string;
  url?: string;
};

export default function useButtonTracking({
  label,
  location,
  url,
}: UseButtonTrackingProps): () => void {
  const pathname = usePathname();

  return useCallback(() => {
    if (!url) {
      return;
    }

    if (isEnrollmentHostname(url)) {
      trackButtonClicked({
        action: ACTIONS.CLICK_ENROLL,
        buttonLocation: location,
        buttonText: label,
        buttonType: BUTTON_TYPES.NAVIGATION,
        destination: url,
        url: pathname,
      });

      return;
    }

    if (url.includes('/request-demo')) {
      trackButtonClicked({
        action: ACTIONS.DEMO_REQUEST,
        buttonLocation: location,
        buttonText: label,
        buttonType: BUTTON_TYPES.NAVIGATION,
        destination: url,
        url: pathname,
      });

      return;
    }

    trackButtonClicked({
      action: ACTIONS.NAVIGATION,
      buttonText: label,
      buttonType: BUTTON_TYPES.NAVIGATION,
      buttonLocation: location,
      destination: url,
      url: pathname,
    });
  }, [label, location, pathname, url]);
}
