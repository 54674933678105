import MarkdownRichText from '@/components/shared/MarkdownRichText';
import styled, { css } from 'styled-components';

import { Heading } from '@swordhealth/ui-corporate';

const TitleStyled = styled(Heading).attrs({ forwardedAs: 'h3' })`
  ${({ $centered }) =>
    $centered &&
    `
    text-align: center;
    `};

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    ${(props) =>
      props.$breakLine &&
      css`
        display: grid;
        white-space: pre-line;
      `};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    text-align: left;
  }
`;

export default function Title({ children, centered, breakLine }) {
  return (
    <TitleStyled $centered={centered} $breakLine={breakLine} size="lg">
      <MarkdownRichText>{children}</MarkdownRichText>
    </TitleStyled>
  );
}
