import { sword } from '@swordhealth/ui-corporate/themes';

// TODO: Remove this after the migration to the new theme is done
const defaultColors = {
  dark_neutral: '#EFE9E6',
};

const theme = {
  ...sword,
  colors: {
    ...defaultColors,
    ...sword.colors,
    inputs: {
      bg: sword.colors.white,
      placeholder: 'rgba(48, 38, 29, 0.5)',
      text: sword.colors.grey[800],
      border: sword.colors.grey[500],
      borderFocus: sword.colors.grey[800],
      error: sword.colors.negative.default,
    },
  },
  typography: {
    ...sword.typography,
    family: {
      body: 'var(--ui-typography-family-body)',
      title: 'var(--ui-typography-family-title)',
    },
  },
  breakpoints: {
    ...sword.breakpoints,
    // TODO: Remove this after the migration to the new theme is done
    ...Object.keys(sword.breakpoints).reduce((acc, key) => {
      const snakeCaseKey = key.replace(/([A-Z])/g, (g) => `_${g[0].toLowerCase()}`);
      acc[snakeCaseKey] = parseInt(sword.breakpoints[key], 10);
      return acc;
    }, {}),
  },
};

export default theme;
