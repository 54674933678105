import styled, { css } from 'styled-components';

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 24px;

  ${(props) =>
    props.$secondary &&
    css`
      grid-template-columns: initial;
    `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    grid-template-columns: 1fr;
    gap: 32px;
  }
`;
export const Text = styled.div`
  align-self: center;
  text-wrap: balance;

  ${(props) =>
    props.$secondary &&
    css`
      justify-self: center;
    `};
`;
export const Buttons = styled.div`
  display: flex;
  gap: 16px;
  justify-self: end;
  align-self: center;

  ${(props) =>
    props.$secondary &&
    css`
      justify-self: center;
      text-align: center;
    `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    justify-self: center;
    flex-direction: column;
    width: 100%;
  }
`;
export const InnerWrapper = styled.div`
  padding-top: ${(props) => (props.$secondary ? '100px' : '120px')};
  padding-bottom: ${(props) => (props.$secondary ? '100px' : '120px')};
  border-bottom: 1px solid rgba(31, 34, 44, 0.1);

  ${(props) =>
    props.$customCta &&
    css`
      background-color: ${(props) => props.theme.colors.neutral.default};
      border: none;
      border-radius: 8px;
      padding: 92px 55px 92px 80px;

      h3 {
        max-width: 490px;
      }
    `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-top: 64px;
    padding-bottom: 64px;

    ${(props) =>
      props.$customCta &&
      css`
        padding: 80px 40px;
        h3 {
          max-width: 90%;
        }
      `};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    ${(props) =>
      props.$customCta &&
      css`
        padding: 48px 16px;
        h3 {
          max-width: 100%;
        }
      `};
  }
`;
export const Wrapper = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.neutral.default};

  h2 {
    padding: 0;
  }

  ${(props) =>
    props.$customCta &&
    css`
      background-color: ${(props) => props.theme.colors.white};
    `};
`;
