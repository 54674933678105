import { Container } from '@/components/core/flexbox';

import Title from './Title';

import useButtonTracking from '@/utils/useButtonTracking';
import Section from '@/components/core/Section';
import { Button } from '@/components/core/Button';
import { Buttons, Content, Text, InnerWrapper, Wrapper } from './styles';

const CTA = ({
  title,
  primaryHref,
  secondaryHref,
  secondary = false,
  spacerBottom = false,
  breakLine = false,
  customCta = false,
}) => {
  const trackPrimaryClick = useButtonTracking({
    label: primaryHref?.label,
    url: primaryHref?.href,
    location: 'cta',
  });
  const trackSecondaryClick = useButtonTracking({
    label: secondaryHref?.label,
    url: secondaryHref?.href,
    location: 'cta',
  });
  return (
    <Section {...(spacerBottom ? {} : { xs: '0', sm: '0', md: '0' })}>
      <Wrapper $customCta={customCta}>
        <Container>
          <InnerWrapper $secondary={secondary} $customCta={customCta}>
            <Content $secondary={secondary}>
              <Text $secondary={secondary}>
                <Title centered={secondary ? true : false} breakLine={breakLine}>
                  {title}
                </Title>
              </Text>
              <Buttons $secondary={secondary}>
                {secondaryHref && (
                  <Button
                    variant="tertiary"
                    href={secondaryHref.href}
                    onClick={trackSecondaryClick}
                  >
                    {secondaryHref.label}
                  </Button>
                )}
                {primaryHref && (
                  <Button href={primaryHref.href} onClick={trackPrimaryClick}>
                    {primaryHref.label}
                  </Button>
                )}
              </Buttons>
            </Content>
          </InnerWrapper>
        </Container>
      </Wrapper>
    </Section>
  );
};

export default CTA;
