// TODO
// After replacing Neutral Button with Pills from ui-corporate,
// this component can be deleted

import styled, { css } from 'styled-components';
import Button from './ButtonOld';
import theme from '@/utils/styles-variables';

const NeutralButton = styled(Button).attrs({
  loadingColor: theme.colors.grey.dark,
})`
  font-family: ${(props) => props.theme.typography.family.title};
  background-color: ${(props) => props.theme.colors.neutral.default};
  white-space: nowrap;
  text-transform: capitalize;

  &:hover {
    background-color: ${(props) => props.theme.colors.neutral.dark};
    color: ${(props) => props.theme.colors.primary.default};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    &:hover {
      background-color: ${(props) => props.theme.colors.neutral.default};
      color: initial;
    }
  }

  ${(props) =>
    props.$active &&
    css`
      background-color: ${props.theme.colors.primary.light};
      color: ${props.theme.colors.primary.dark};

      &:hover {
        background-color: ${props.theme.colors.primary.light};
        color: ${props.theme.colors.primary.dark};
      }

      @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
        &:focus,
        &:hover {
          background-color: ${(props) => props.theme.colors.primary.light};
          color: ${(props) => props.theme.colors.primary.dark};
        }
      }
    `};
`;

export default NeutralButton;
