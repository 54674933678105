import styled from 'styled-components';

export const StyledSmallTitle = styled.p`
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  font-family: ${({ theme }) => theme.typography.family.title};
  margin-bottom: 0;
  margin-top: 0;
`;

function SmallTitle({ children, ...rest }) {
  return <StyledSmallTitle {...rest}>{children}</StyledSmallTitle>;
}

export default SmallTitle;
