import MarkdownRichText from '@/components/shared/MarkdownRichText';
import styled from 'styled-components';

const StyledTitle = styled.h1`
  max-width: 553px;
  font-weight: 300;
  font-size: 76px;
  line-height: 80px;
  color: ${(props) => props.theme.colors.grey.dark};
  margin-bottom: 24px;

  strong,
  i {
    font-weight: 700;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 55px;
    line-height: 64px;
    max-width: 375px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 34px;
    line-height: 40px;
  }
`;

const ExtraWrapper = styled.span`
  position: relative;
`;

const Extra = styled.span`
  position: absolute;
  top: -3px;
  right: -20px;
  color: #a4aab6;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 30px;
    line-height: 40px;
    font-family: ${(props) => props.theme.typography.family.title};
  }
`;

const Title = ({ children, extraLabel }) => {
  return (
    <StyledTitle>
      <MarkdownRichText elements={['underline']}>{children}</MarkdownRichText>
      {extraLabel && (
        <ExtraWrapper>
          <Extra>*</Extra>
        </ExtraWrapper>
      )}
    </StyledTitle>
  );
};

export default Title;
