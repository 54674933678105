import Link from 'next/link';
import styled, { css } from 'styled-components';

import useButtonTracking from '@/utils/useButtonTracking';

const Wrapper = styled.div`
  width: 33.3%;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 80px;

  &:nth-child(4),
  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-bottom: 56px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 50%;
    margin-bottom: 48px;

    &:nth-child(4) {
      margin-bottom: 48px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const text = css`
  font-family: ${(props) => props.theme.typography.family.title};
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  width: fit-content;
  margin-bottom: 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 16px;
  }
`;

const StyledLink = styled.div`
  ${text}
`;

const Title = styled.div`
  ${text}
`;

const MainLink = ({ name, href, newTab, children }) => {
  const trackClick = useButtonTracking({ label: name, url: href, location: 'footer' });

  return (
    <Wrapper>
      {href ? (
        <Link
          href={href}
          target={newTab ? '_blank' : undefined}
          rel={newTab ? 'noreferrer' : undefined}
          onClick={trackClick}
        >
          <StyledLink>{name}</StyledLink>
        </Link>
      ) : (
        <Title>{name}</Title>
      )}
      {children}
    </Wrapper>
  );
};

export default MainLink;
