'use client';

import { useCallback, useId, useRef, useState } from 'react';
import styled from 'styled-components';
import cn from 'classnames';

import { Button, InputControlled } from '@swordhealth/ui-corporate';

function getFileError(type, options) {
  switch (type) {
    case 'fileLimitMax':
      return `Please select up to ${options.maxFiles} files`;
    case 'fileLimitMin':
      return `Please select at least ${options.minFiles} files`;
    case 'fileRead':
      return 'Error reading file';
    case 'fileSize':
      return `Please upload a file under ${options.maxSize}MB`;
    case 'reading':
      return 'An error occurred reading your files';
    default:
      return 'Error uploading file';
  }
}

function validateFileSize(file, maxSize) {
  if (!file || file.size > maxSize * 1024 * 1024) {
    return false;
  }

  return true;
}

function getFileData(file, data) {
  return {
    name: file.name,
    data,
    size: file.size,
    type: file.type,
  };
}

export function readFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onerror = () => reject(getFileError('fileRead'));
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve(getFileData(file, reader.result));
      } else {
        reject(getFileError('fileRead'));
      }
    };

    reader.readAsDataURL(file);
  });
}

export async function fileFromFileData(fileData) {
  const res = await fetch(fileData.data);
  const buf = await res.arrayBuffer();

  return new File([buf], fileData.name, { type: fileData.type });
}

const FileUpload = ({
  accept,
  asPlaceholder,
  className,
  label,
  error,
  errorMessage,
  helperMessage,
  field: { name, ...field },
  minFiles,
  maxFiles,
  maxSize = 5, // MB
  multiple = false,
  ...props
}) => {
  const fileInputEl = useRef(null);
  const fakeInputEl = useRef(null);
  const id = useId();
  const [fileNames, setFileNames] = useState([]);

  const validateField = useCallback(
    (filesList, required) => {
      const filesTotal = filesList.length;

      if (!filesList.length) {
        if (required) {
          return 'This field is required';
        }
        return false;
      }

      if (maxFiles && maxFiles < filesTotal) {
        return getFileError('fileLimitMax', { maxFiles });
      }

      if (minFiles && minFiles > filesTotal) {
        return getFileError('fileLimitMin', { minFiles });
      }

      for (let i = 0; i < filesTotal; i++) {
        if (!validateFileSize(filesList[i], maxSize)) {
          return getFileError('fileSize', { maxSize });
        }
      }

      return false;
    },
    [maxFiles, minFiles, maxSize],
  );

  const updateFile = useCallback(
    async (filesList) => {
      const parsedFiles = await Promise.all(
        Array.from(filesList).map(async (file) => {
          return await readFile(file);
        }),
      );

      setFileNames(parsedFiles.map((file) => file.name));

      field.setValue(multiple ? parsedFiles : parsedFiles[0]);

      fakeInputEl.current?.focus();
    },
    [field, multiple],
  );

  return (
    <fieldset
      className={cn(['fieldbox-wrap', className])}
      onClick={() => fileInputEl.current?.click()}
    >
      <div className={cn('fieldbox', { error })}>
        <input
          ref={fileInputEl}
          className="file-input"
          {...(accept ? { accept: accept.join(',') } : {})}
          name={name}
          onChange={async (e) => {
            const filesList = e.target.files;
            const fileError = validateField(filesList, props.required);

            if (fileError) {
              field.setValue('', false);
              field.setError(fileError);
              setFileNames([]);

              return;
            }

            await updateFile(filesList);
          }}
          id={id}
          multiple={multiple}
          type="file"
        />
        <InputControlled
          ref={fakeInputEl}
          type="text"
          label={label}
          required={props.required}
          asPlaceholder={asPlaceholder}
          errorMessage={errorMessage}
          helperMessage={helperMessage}
          className="fake-input"
          value={fileNames.join(', ')}
          aria-hidden="true"
          tabIndex="-1"
          readOnly
          suffix={
            <Button as="span" variant="secondary-dark" className="file-button">
              Browse
            </Button>
          }
        />
      </div>
    </fieldset>
  );
};

const FileUploadStyled = styled(FileUpload)`
  cursor: pointer;
  display: block;

  .file-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    &:focus + .fake-input {
      --field-box-border: ${(props) => props.theme.colors.grey.dark};
    }
  }

  .fake-input {
    pointer-events: none;
  }

  .file-button {
    --button-bg: ${(props) => props.theme.colors.neutral[300]};
    --button-border-radius: ${(props) => props.theme.borderRadius.lg};

    margin: -${(props) => props.theme.spacings.xs} -${(props) => props.theme.spacings['2xs']};
  }
`;

export default FileUploadStyled;
