import { forwardRef } from 'react';

import { Heading, RichText } from '@swordhealth/ui-corporate';

const TITLE_VARIANTS = ['default', 'gradient', 'gradientRichText'];

const Title = forwardRef(({ children, size = 'lg', variant = TITLE_VARIANTS[0], ...rest }, ref) => {
  return (
    <Heading {...rest} variant={variant} ref={ref} size={size}>
      <RichText as="span" highlightLinks gradientBoldText={variant === TITLE_VARIANTS[2]}>
        {children}
      </RichText>
    </Heading>
  );
});

Title.displayName = 'Title';

const H1 = (props) => <Title {...props} as="h1" />;
const H2 = (props) => <Title {...props} as="h2" />;
const H3 = (props) => <Title {...props} as="h3" />;
const H4 = (props) => <Title {...props} as="h4" />;

export default Title;
export { H1, H2, H3, H4 };
